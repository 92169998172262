/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "scss/theme";

html,
body {
  height: 100%;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  background-color: #f2f4f5;
}

// button css

.mat-flat-button {
  font-family: Montserrat !important;
  color: #ffffff !important;
  border-radius: 0 !important;
  padding: 9px 63px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.mat-flat-button:hover {
  background-color: #4f4f4f !important;
}

.mat-flat-button.mat-accent {
  color: #4f4f4f !important;
  background-color: #f2f4f5 !important;
}

/* Table CSS */

.table {
  vertical-align: middle;
}

.break-div {
  flex-basis: 100% !important;
  height: 0 !important;
}

.w-70 {
  width: 70% !important;
}

.upload-image-drop-zone {
  width: 100%;
  height: 200px;
  // margin: 21px 2px 0 0;
  padding: 15px 15px;
  border: solid 1px #c1c1c1;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-image-content {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  margin-top: 75px;
}

.dropdown-menu {
  display: none !important;
}

.dropdown-menu.show {
  display: block !important;
}

.fixed-top {
  z-index: 999;
}

.mat-menu-panel {
  border-radius: 0 !important;
}

.document-menu {
  margin-top: 0.5rem;
  border: solid 1px #f0f0f0;
  box-shadow: none;
  width: 156px;

  label {
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #4f4f4f;
    padding: 0 10px;
  }

  .selected-document {
    background: rgba(0, 0, 0, 0.04) !important;
  }
}

.profile-menu {
  margin-top: 0.5rem;
  border: solid 1px #f0f0f0;
  box-shadow: none;

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(20px, 0, 0) !important;
  }

  .slide-toggle {
    position: relative;
    top: 6px;

    .mat-slide-toggle-label {
      flex-direction: row-reverse !important;
    }

    .mat-slide-toggle-thumb {
      background-color: #ffffff !important;
      width: 18px !important;
      height: 18px !important;
      margin-top: 4px !important;
      margin-left: 2px !important;
    }

    .mat-slide-toggle-bar {
      border-radius: 12px !important;
      margin-left: 16px !important;
    }

    .mat-slide-toggle-ripple {
      top: calc(50% - 17px) !important;
      left: calc(50% - 17px) !important;
    }

    .mat-slide-toggle-bar {
      width: 42px !important;
      height: 20px !important;
    }
  }

  img {
    margin-right: 0.5rem;
  }

  label {
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #4f4f4f;
  }
}

.app-full-bleed-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}

.app-pdf-preview-dialog .mat-dialog-container {
  padding: 0;
  overflow: hidden;
}

::ng-deep .app-pdf-preview-dialog .close-button {
  float: right;
  top: -9px;
  z-index: 999;
  color: white !important;
  opacity: 1;
  position: absolute;
  right: -39px;
}

.only-pdf-preview .mat-dialog-container {
  padding: 0;
  overflow: unset;
}

::ng-deep .only-pdf-preview .close-button {
  float: right;
  top: -9px;
  z-index: 999;
  color: white !important;
  opacity: 1;
  position: absolute;
  right: -39px;
}

.mat-dialog-container {
  border-radius: 0 !important;
}

.popup-backdrop-background {
  background-color: #000000 !important;
  opacity: 80% !important;
}

.signature-div {
  position: absolute;
  //top: 18px;
  //left: 36px;
  width: 180px;
  height: 80px;
  background-color: #f5f5f5;
  padding: 18px;
  z-index: 9;
  text-align: center;
  border: solid 1px #089a6c;
  cursor: pointer;

  label {
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #6a6a6a;
  }
}

.cursor-pointer {
  cursor: pointer;
}