.menu-background {
  background-color: $header-background-color !important;
}

.main-header .navbar {
  box-shadow: 0 3px 3px 0 $header-box-shadow-color !important;
  background-color: $header-background-color !important;
}

.business-process {
  background-color: $top-section-background-color !important;
}

.body-background {
  background-color: $body-background-color !important;
}

li.nav-item {
  .mat-icon {
    color: $header-icon-color !important;

    &:hover {
      color: $global-green-color !important;
    }

    &.open-profile-menu {
      color: $global-green-color !important;
    }
  }
}

.heading-color {
  color: $heading-color !important;
}

.secondary-heading-color {
  color: $secondary-heading-color !important;
}

.border-green-color {
  border-color: $global-green-color !important;
}

.card-templates .card-image {
  border-color: $card-border-color !important;
  background-color: $white !important;
}

.add-doc-top-section {
  background-color: $top-section-background-color-add-document !important;
}

.under-title-line {
  background-color: $global-green-color !important;
}

.horizontal-connected-line {
  background-color: $horizontal-connected-line !important;
}

.step-label-enable {
  color: $global-green-color !important;
}

.step-label-disable {
  color: $step-label-disable !important;
}

.heading-add-document,
.advance-settings-title,
.advance-settings-sub-title {
  color: $heading-color-dark-add-document !important;
}

.sub-title,
.card-title-add-doc,
.main-title,
.home-to-click a {
  color: $heading-color-add-document !important;
}

.file-upload-title {
  color: $global-green-color !important;
}

.file-upload-sub-title,
.advance-settings-label {
  color: $file-upload-sub-title-color !important;
}

.file-name {
  color: $heading-color-dark-add-document !important;
}

.global-green-color {
  color: $global-green-color !important;
}
